import { constructURLData, getData, postData } from "../api";

const defaultSucces = () => console.log("Функция success не задана");
const defaultError = () => console.log("Функция error не задана");

const SSO_URL = process.env.REACT_APP_API_URL

export const Authorization = {
    loadSSOAuthData: (
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Authorization/ExternalAuthSettings`;
        getData(url, {}, SSO_URL).then(success).catch(err);
    },

    loginVK: (
        data = {
            vkToken: "",
            redirectUri: ""
        },
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Authorization/Login/Vk`;
        postData(url, data, SSO_URL, { contentType: "" }).then(success).catch(err);
    },
    loginYandex: (
        data = { yandexToken: "" },
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Authorization/Login/Yandex`;
        postData(url, data, SSO_URL, { contentType: "" }).then(success).catch(err);
    },

    loginGoogle: (
        data = { googleToken: "" },
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Authorization/Login/Google`;
        postData(url, data, SSO_URL, { contentType: "" }).then(success).catch(err);
    },

    login: (
        data = {
            email: "",
            password: "",
        },
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Authorization/Login`;
        postData(url, data, SSO_URL).then(success).catch(err);
    },

    forgotPassword: (
        data = {
            email: "",
        },
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Authorization/ForgotPassword`;
        postData(url, data, SSO_URL).then(success).catch(err);
    },
    resetPassword: (
        data = {
            resetId: "",
            newPassword: ""
        },
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Authorization/ResetPassword`;
        postData(url, data, SSO_URL).then(success).catch(err);
    },

    confirmEmail: (
        data = {
            confirmationId: "",
        },
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Registration/ConfirmEmail/` + data.confirmationId;
        postData(url, {}, SSO_URL).then(success).catch(err);
    },

    register: (
        data = {
            email: "",
            password: "",
            fullName: {
                first: "",
                last: "",
                middle: ""
            }
        },
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Registration/Register`;
        postData(url, data, SSO_URL).then(success).catch(err);
    },
}



export const SSO = {
    Authorize: (
        request,
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `Sso/Authorize?` + constructURLData({ request });
        postData(url, {}, SSO_URL).then(success).catch(err);
    },

    AcceptResponse: (
        response,
        responseUrl,
        success = defaultSucces,
        err = defaultError
    ) => {
        // const url = `?` + constructURLData({ response });
        postData("", { response }, responseUrl, { contentType: "multipart/form-data" }).then(success).catch(err);
    },
}



export const User = {
    getInfo: (
        success = defaultSucces,
        err = defaultError
    ) => {
        const url = `User/Info`;
        getData(url, {}, SSO_URL).then(success).catch(err);
    },
}
