
import { useState, useEffect } from "react";

import styles from "./AlertMessage.module.scss";

import { Row } from '../../Row/Row'

export const AlertMessage = ({ status, message, statusText, time }) => {
  const [isClosed, setIsClosed] = useState(false);
  const open = () => setIsClosed(false)
  const close = () => setIsClosed(true)


  useEffect(() => {
    open()
  }, [time])

  if (isClosed) {
    return null
  }


  return (
    <div className={styles.container}>
      <Row>
        <div className={styles.status}>
          {status}
        </div>
        <div className={styles.statusText}>
          {statusText}
        </div>
      </Row>

      <div className={styles.message}>
        {message}
      </div>

      <div onClick={close} className={styles.close}>
        &times;
      </div>
    </div>
  );
};
