import styles from "./Button.module.scss";

export const Button = ({
  onClick,
  children,
  disabled = false,
  onSubmit,
  type = "primary",
  ...props
}) => {
  const btnClassName = `${styles.button} ${styles[type]}`;

  return (
    <button
      {...props}
      className={btnClassName}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
