import React from "react";

import styles from "./Checkbox.module.scss";

export const Checkbox = ({ value = false, onChange, children }) => {
  return (
    <div className={styles.checkbox}>
      <label>
        <input type="checkbox" checked={value} onChange={onChange} />
        <i className={styles['input-helper']}></i>
        {children}
      </label>
    </div>
  );
};
