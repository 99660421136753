import { Button } from "../Button";

export const ButtonSSO = ({
  onClick,
  children,
  type = "primary",
  ...props
}) => {
  return (
    <Button
      style={{ padding: "10px 10px 12px 10px", margin: "10px 5px" }}
      onClick={onClick}
      type={type}
      {...props}
    >
      {children}
    </Button>
  );
};
