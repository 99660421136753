import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";

import { WindowCenteredContainer } from "../components/UI-kit/containers/WindowCenteredContainer/WindowCenteredContainer";
import { SSO } from "../utils/methods/auth";
import { getQueryParameter } from "../utils/methods/router";

export function SSOLogin(props) {
  const history = useHistory();

  const formRef = useRef(null);

  const [acceptResponseData, setAcceptResponseData] = useState({
    response: "",
    responseUrl: "",
  });

  useEffect(() => {
    let request = getQueryParameter(props, "request");

    if (request) {
      const encodedRequest = encodeURIComponent(request);
      localStorage.setItem("request", encodedRequest);
      checkAuthorize(request);
    }
  }, []);

  const checkAuthorize = (request) => {
    SSO.Authorize(request, successAuth, logError);
  };

  const successAuth = (res) => {
    setAcceptResponseData(res);
    // SSO.AcceptResponse(res.response, res.responseUrl, finishLoad, logError)
  };

  const finishLoad = (res) => {
    console.log(res.url);
  };

  const logError = (err) => {
    history.push("/auth");
  };

  useEffect(() => {
    if (acceptResponseData.responseUrl && formRef) {
      console.log(acceptResponseData.responseUrl, acceptResponseData.response);
      formRef.current?.submit();
    }
  }, [acceptResponseData]);

  return (
    <WindowCenteredContainer>
      Через несколько секунд вы будете перенаправлены на страницу авторизации
      <form
        ref={formRef}
        style={{ visibility: "hidden" }}
        action={acceptResponseData.responseUrl}
        onError={logError}
        enctype="multipart/form-data"
        method="post">
        <input name="response" value={acceptResponseData.response} />
      </form>
    </WindowCenteredContainer>
  );
}
