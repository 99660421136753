import { useEffect, useMemo, useState } from "react";


import { Button } from "../components/UI-kit/Button/Button";
import { WindowCenteredContainer } from "../components/UI-kit/containers/WindowCenteredContainer/WindowCenteredContainer";
import { Input } from "../components/UI-kit/Input/Input";
import { Row } from "../components/UI-kit/Row/Row";
import { Title } from "../components/UI-kit/Title/Title";

import { useHistory } from "react-router";
import { Text } from "../components/UI-kit/Text/Text";
import { UsualText } from "../components/UI-kit/UsualText/UsualText";

import check from "../assets/svg/circle-check.svg"

export function SuccessRegister() {
  const router = useHistory()

  const goToAuth = () => router.push("/auth")

  return (
    <WindowCenteredContainer
      style={{ backgroundColor: "rgb(245, 247, 250)", padding: "20px 80px 80px 80px", minWidth: 500, borderRadius: 20 }}
    >
      <Title />

      <img src={check} style={{ margin: "20px 0", width: 48 }} />

      <UsualText bold>Вы успешно зарегистрировались</UsualText>

      <UsualText>Благодарим вас за регистрацию. Ваш пароль был выслан на почту.</UsualText>
      <Button onClick={goToAuth} style={{ marginTop: 20 }} type={"primary"}>
        Продолжить
      </Button>
    </WindowCenteredContainer>
  );
}
