import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth } from "./pages/Auth";
import { Register } from "./pages/Register";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

import { ConfirmEmail } from "./pages/ConfirmEmail";

import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";

import { VKAuth } from "./pages/VKAuth";
import { YandexAuth } from "./pages/YandexAuth";
import { NotFoundPage } from "./pages/NotFoundPage";
import { SuccessRegister } from "./pages/SuccessRegister";
import { SSOLogin } from "./pages/SSOLogin";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    clearToken();
  }, []);

  const clearToken = () => localStorage.removeItem("token");

  return (
    <Router>
      <Switch>
        <Route exact path="/auth" component={Auth} />
        <Route path="/register" component={Register} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/register-success" component={SuccessRegister} />

        <Route path="/sso-login" component={SSOLogin} />

        <Route path="/confirm-email" component={ConfirmEmail} />

        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />

        <Route path="/auth/vk" component={VKAuth} />
        <Route path="/auth/yandex" component={YandexAuth} />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
