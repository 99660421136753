import { useHistory } from "react-router"

export const getQueryParameter = (props, key) => {
    return (new URLSearchParams(props.location.search).get(key))
}

export const parseHashObject = (props) => {
    const obj = {}

    let { hash } = props.location
    hash = hash.replace("#", '')

    hash.split("&").map((el) => {
        const [key, value] = el.split("=")

        obj[key] = decodeURI(value)
    })

    return obj
}


export const useGoToSsoLogin = () => {
    const history = useHistory()
    const request = localStorage.getItem("request")

    return () => history.push("/sso-login?request=" + decodeURI(request))
}