import { useEffect } from "react";


import { Authorization } from "../utils/methods/auth";
import { WindowCenteredContainer } from "../components/UI-kit/containers/WindowCenteredContainer/WindowCenteredContainer";
import { useHistory } from "react-router";
import { getQueryParameter } from "../utils/methods/router";


export function ConfirmEmail(props) {

  const router = useHistory()

  useEffect(() => {
    confirm()
  }, [])

  const confirm = () => {
    let confirmationId = getQueryParameter(props, "confirmationId")

    if (confirmationId) {
      Authorization.confirmEmail({ confirmationId }, success, fail)
    }

    function success() {
      router.push("/auth?confirm=2")
    }

    function fail(err) {
      router.push("/auth?confirm=1")
    }
  }

  return (

    <WindowCenteredContainer>Через несколько секунд вы будете перенаправлены на страницу авторизации</WindowCenteredContainer>
  );
}
