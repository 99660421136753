import { useEffect, useState } from "react";

import { useHistory } from "react-router";


import { Button } from "../components/UI-kit/Button/Button";
import { WindowCenteredContainer } from "../components/UI-kit/containers/WindowCenteredContainer/WindowCenteredContainer";
import { Input } from "../components/UI-kit/Input/Input";
import { Title } from "../components/UI-kit/Title/Title";

import { Authorization } from "../utils/methods/auth";
import { Alert } from "../components/UI-kit/Alert/Alert";
import { useKeyPressEvent } from "../utils/hooks/useKeyPressEvent";

import { getQueryParameter } from "../utils/methods/router";

import { Link } from "react-router-dom";

export function ResetPassword(props) {

  const router = useHistory()

  const [data, setData] = useState({
    password: "",
    repeatPassword: "",
  });

  const [isAgreePolicy, setIsAgreePolicy] = useState(false)

  const toggleIsAgreePolicy = () => setIsAgreePolicy(!isAgreePolicy)

  const [isCorrectPassword, setIsCorrectPassword] = useState(true)

  const [error, setError] = useState(null)

  const [isSuccessReset, setIsSuccessReset] = useState(false)

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    checkPasswordCorrect()
  }, [data.password, data.repeatPassword])

  const checkPasswordCorrect = () => {
    const { password, repeatPassword } = data
    const isCorrect = password == repeatPassword
    setIsCorrectPassword(isCorrect)
  }


  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const resetPassword = () => {

    let resetId = getQueryParameter(props, "resetId")
    const { password } = data

    setIsLoading(true)
    Authorization.resetPassword({ resetId, newPassword: password }, success, fail)

    function success(res) {
      setIsLoading(false)
      setError(null)
      setIsSuccessReset(true)
    }
  }

  const fail = (err) => {
    setIsLoading(false)
    err.then((errDescription) => {
      const message = errDescription.message
      const email = errDescription?.validationErrors?.email

      if (email) {
        setError(email)
        return
      }

      setError(message)
    })
  }


  const isEmptyFields = data.email == "" || data.name == "" || data.password == "" || data.repeatPassword == ""


  const isSubmitDisabled = isEmptyFields || !isCorrectPassword || isLoading
  useKeyPressEvent(isSubmitDisabled ? null : resetPassword, "Enter", "NumpadEnter")

  return (
    <>
      <WindowCenteredContainer>
        <Title />
        {isSuccessReset ? (
          <>
            <Alert isShow={true} type="success">
              Вы успешно сбросили пароль
            </Alert>
            <Button style={{ marginTop: 20 }} type={"primary"}>
              <Link to="/auth">Войти в систему</Link>
            </Button>
          </>
        ) : (
          <>
            <Input
              onChange={handleInputChange}
              type="password"
              name="password"
              onBlur={checkPasswordCorrect}
              warning={!isCorrectPassword}
              value={data.password}
              placeholder={"Пароль"}
            />
            <Input
              onChange={handleInputChange}
              type="password"
              name="repeatPassword"
              onBlur={checkPasswordCorrect}
              warning={!isCorrectPassword}
              value={data.repeatPassword}
              placeholder={"Повторите пароль"}
            />

            {error ? <Alert type="warning">{error}</Alert> : null}

            <Alert isShow={!isCorrectPassword} type="warning">
              Пароли не совпадают. Введите одинаковые пароли.
            </Alert>

            <Alert isShow={error == null && isCorrectPassword && data.password && data.repeatPassword} type="success">
              Пароли совпадают
            </Alert>

            <Button onClick={resetPassword} disabled={isSubmitDisabled} style={{ marginTop: 20 }} type={"primary"}>
              Сохранить изменения
            </Button>
          </>
        )}
      </WindowCenteredContainer>
    </>
  );
}
