import styles from "./Tab.module.scss";

import { useHistory } from "react-router";
import { Link } from "react-router-dom";


export const Tab = ({ option = { label: "", href: "" } }) => {
  const router = useHistory()

  const { label, href } = option

  const isActive = router.location.pathname === href

  return <Link className={styles.container} to={href}>
    <a className={isActive ? styles.href + " " + styles.active : styles.href}> {label}</a>
  </Link>;
};
