import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Authorization } from '../utils/methods/auth'
import { parseHashObject, useGoToSsoLogin } from '../utils/methods/router'

export function YandexAuth(props) {
    const router = useHistory()
    const goToSsoLogin = useGoToSsoLogin()


    useEffect(() => {
        const params = parseHashObject(props)

        if (params?.access_token)
            yandexAuth(params?.access_token)

    }, [router])

    const yandexAuth = (access_token) => {
        if (access_token) {
            const data = { yandexToken: access_token }
            Authorization.loginYandex(data, success, error)
        }

        function success(res) {
            localStorage.setItem("token_service", "yandex")
            localStorage.setItem("token", res.token)
            goToSsoLogin()
        }

        function error(err) {
            console.log(err);
        }
    }

    return <div>Вы успешно авторизовались через Yandex</div>

}
